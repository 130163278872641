/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import styled from 'styled-components';

/**
 * Export `baseFontSize`.
 */

export const baseFontSize = 16;

/**
 * Export `minFluidBreakpoint`.
 */

export const minFluidBreakpoint = 480 / baseFontSize;

/**
 * Export `maxFluidBreakpoint`.
 */

export const maxFluidBreakpoint = 1440 / baseFontSize;

/**
 * Export `clampCalc`.
 */

export const clampCalc = (min: number, max: number) => {
  const slope = (max / baseFontSize - min / baseFontSize) / (maxFluidBreakpoint - minFluidBreakpoint);

  const intersection = -minFluidBreakpoint * slope + min / baseFontSize;

  return `${intersection.toFixed(4)}rem +
    ${(slope * 100).toFixed(4)}vw`;
};

/**
 * `FontProperties` type.
 */

export interface FontProperties {
  fontFamily: string;
  fontSize: number;
  fontSizeMax: number;
  fontSizeMin: number;
  fontWeight: number;
  letterSpacing?: number;
  letterSpacingMax?: number;
  letterSpacingMin?: number;
  lineHeight: number;
  lineHeightMax: number;
  lineHeightMin: number;
}

/**
 * Check if value is truly and return result, otherwise returns empty string.
 */

const ifValue = (value: string | number | boolean | undefined, result: string): string => {
  return (value && result) || '';
};

/**
 * Export `createFontStyles` util.
 */

export const createFontStyles = (properties: FontProperties): string => {
  const {
    fontFamily,
    fontSize,
    fontSizeMax,
    fontSizeMin,
    fontWeight,
    letterSpacing = 0,
    letterSpacingMax,
    letterSpacingMin,
    lineHeight,
    lineHeightMax,
    lineHeightMin
  } = properties;

  const letterSpacingMaximum = letterSpacingMax ?? letterSpacing;
  const letterSpacingMinimum = letterSpacingMin ?? letterSpacing;
  const isFluidSize = fontSizeMin && fontSizeMax && lineHeightMax && lineHeightMin;

  return `
    ${ifValue(fontFamily, `font-family: ${fontFamily};`)}
    ${ifValue(fontWeight, `font-weight: ${fontWeight};`)}
    ${ifValue(letterSpacing, `letter-spacing: ${letterSpacing}px;`)}

    ${ifValue(
      !isFluidSize,
      `
      font-size: ${fontSize / baseFontSize}rem,;
      line-height: ${lineHeight / baseFontSize}rem,;
      `
    )}

    ${ifValue(
      isFluidSize,
      `
      font-size: clamp(${fontSizeMin / baseFontSize}rem, ${clampCalc(fontSizeMin, fontSizeMax)}, ${
        fontSizeMax / baseFontSize
      }rem);
      letter-spacing: clamp(${letterSpacingMinimum / baseFontSize}rem, ${clampCalc(
        letterSpacingMinimum,
        letterSpacingMaximum
      )}, ${letterSpacingMaximum / baseFontSize}rem);
      line-height: clamp(${lineHeightMin / baseFontSize}rem, ${clampCalc(lineHeightMin, lineHeightMax)}, ${
        lineHeightMax / baseFontSize
      }rem);
      `
    )}
    margin: 0 0 1.5rem;
    padding: 0;
  `;
};

/**
 * `titleXL` styles.
 */

const titleXL = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 175,
  fontSizeMax: 175,
  fontSizeMin: 55,
  fontWeight: 400,
  letterSpacing: 26,
  letterSpacingMax: 26,
  letterSpacingMin: 8.25,
  lineHeight: 175,
  lineHeightMax: 175,
  lineHeightMin: 55
} as const;

/**
 * `titleL` styles.
 */

const titleL = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 155,
  fontSizeMax: 155,
  fontSizeMin: 55,
  fontWeight: 400,
  letterSpacing: 7.75,
  letterSpacingMax: 7.75,
  letterSpacingMin: 2.75,
  lineHeight: 155,
  lineHeightMax: 155,
  lineHeightMin: 55
} as const;

/**
 * `titleM` styles.
 */

const titleM = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 135,
  fontSizeMax: 135,
  fontSizeMin: 55,
  fontWeight: 400,
  letterSpacing: 6.75,
  letterSpacingMax: 6.75,
  letterSpacingMin: 2.75,
  lineHeight: 135,
  lineHeightMax: 135,
  lineHeightMin: 55
} as const;

/**
 * `titleS` styles.
 */

const titleS = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 95,
  fontSizeMax: 95,
  fontSizeMin: 55,
  fontWeight: 400,
  letterSpacing: 14.25,
  letterSpacingMax: 14.25,
  letterSpacingMin: 8.25,
  lineHeight: 95,
  lineHeightMax: 95,
  lineHeightMin: 55
} as const;

/**
 * `titleXS` styles.
 */

const titleXS = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 45,
  fontSizeMax: 45,
  fontSizeMin: 25,
  fontWeight: 400,
  letterSpacing: 2.25,
  letterSpacingMax: 2.25,
  letterSpacingMin: 2.25,
  lineHeight: 45,
  lineHeightMax: 45,
  lineHeightMin: 25
} as const;

/**
 * `titleXXS` styles.
 */

const titleXXS = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 25,
  fontSizeMax: 25,
  fontSizeMin: 22,
  fontWeight: 400,
  letterSpacing: 1.25,
  letterSpacingMax: 1.25,
  letterSpacingMin: 1.25,
  lineHeight: 25,
  lineHeightMax: 25,
  lineHeightMin: 22
} as const;

/**
 * `subTitle` styles.
 */

const subTitle = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 22,
  fontSizeMax: 22,
  fontSizeMin: 22,
  fontWeight: 400,
  letterSpacing: 9.9,
  letterSpacingMax: 9.9,
  letterSpacingMin: 8.25,
  lineHeight: 22,
  lineHeightMax: 22,
  lineHeightMin: 22
} as const;

/**
 * `titleBoldL` styles.
 */

const titleBoldL = {
  fontFamily: 'futura-pt, sans-serif',
  fontSize: 95,
  fontSizeMax: 95,
  fontSizeMin: 55,
  fontWeight: 800,
  letterSpacing: 4.75,
  letterSpacingMax: 4.75,
  letterSpacingMin: 4.75,
  lineHeight: 95,
  lineHeightMax: 95,
  lineHeightMin: 55
} as const;

/**
 * `titleBoldM` styles.
 */

const titleBoldM = {
  fontFamily: 'futura-pt, sans-serif',
  fontSize: 80,
  fontSizeMax: 80,
  fontSizeMin: 55,
  fontWeight: 800,
  letterSpacing: 4,
  letterSpacingMax: 4,
  letterSpacingMin: 4,
  lineHeight: 80,
  lineHeightMax: 80,
  lineHeightMin: 55
} as const;

/**
 * `link` styles.
 */

const link = {
  fontFamily: 'futura-pt-bold, sans-serif',
  fontSize: 15,
  fontSizeMax: 15,
  fontSizeMin: 15,
  fontWeight: 400,
  letterSpacing: 12,
  lineHeight: 15,
  lineHeightMax: 15,
  lineHeightMin: 15
} as const;

/**
 * `header` styles.
 */

const header = {
  fontFamily: 'futura-pt, sans-serif',
  fontSize: 25,
  fontSizeMax: 25,
  fontSizeMin: 16,
  fontWeight: 400,
  letterSpacing: 11.25,
  letterSpacingMax: 11.25,
  letterSpacingMin: 7.2,
  lineHeight: 25,
  lineHeightMax: 25,
  lineHeightMin: 19
} as const;

/**
 * `body` styles.
 */

const body = {
  fontFamily: 'futura-pt, sans-serif',
  fontSize: 22,
  fontSizeMax: 22,
  fontSizeMin: 19,
  fontWeight: 400,
  letterSpacing: 0.47,
  letterSpacingMax: 0.47,
  letterSpacingMin: 0.47,
  lineHeight: 22,
  lineHeightMax: 22,
  lineHeightMin: 19
} as const;

/**
 * `quote` styles.
 */

const quote = {
  fontFamily: 'baskerville-urw, serif',
  fontSize: 19,
  fontSizeMax: 19,
  fontSizeMin: 17,
  fontWeight: 400,
  lineHeight: 19,
  lineHeightMax: 19,
  lineHeightMin: 17
} as const;

/**
 * `quoteL` styles.
 */

const quoteL = {
  fontFamily: 'baskerville-urw, serif',
  fontSize: 95,
  fontSizeMax: 95,
  fontSizeMin: 55,
  fontWeight: 400,
  lineHeight: 95,
  lineHeightMax: 95,
  lineHeightMin: 55
} as const;

/**
 * `handwrittenL` styles.
 */

const handwrittenL = {
  fontFamily: 'GlowBetter-Script, cursive',
  fontSize: 125,
  fontSizeMax: 125,
  fontSizeMin: 63,
  fontWeight: 400,
  lineHeight: 125,
  lineHeightMax: 125,
  lineHeightMin: 63
} as const;

/**
 * `handwrittenM` styles.
 */

const handwrittenM = {
  fontFamily: 'GlowBetter-Script, cursive',
  fontSize: 105,
  fontSizeMax: 105,
  fontSizeMin: 40,
  fontWeight: 400,
  lineHeight: 105,
  lineHeightMax: 105,
  lineHeightMin: 40
} as const;

/**
 * `handwrittenS` styles.
 */

const handwrittenS = {
  fontFamily: 'GlowBetter-Script, cursive',
  fontSize: 60,
  fontSizeMax: 60,
  fontSizeMin: 40,
  fontWeight: 400,
  lineHeight: 90,
  lineHeightMax: 90,
  lineHeightMin: 60
} as const;

/**
 * `quoteBlock` styles.
 */

const quoteBlock = {
  fontFamily: 'adobe-handwriting-ernie, serif',
  fontSize: 33,
  fontSizeMax: 33,
  fontSizeMin: 25,
  fontWeight: 400,
  lineHeight: 33,
  lineHeightMax: 33,
  lineHeightMin: 25
} as const;

/**
 * Variants themes.
 */

export const textStyles = {
  boldL: createFontStyles(titleBoldL),
  boldM: createFontStyles(titleBoldM),
  handwrittenL: createFontStyles(handwrittenL),
  handwrittenM: createFontStyles(handwrittenM),
  handwrittenS: createFontStyles(handwrittenS),
  header: createFontStyles(header),
  link: createFontStyles(link),
  paragraph: createFontStyles(body),
  quote: createFontStyles(quote),
  quoteBlock: createFontStyles(quoteBlock),
  quoteL: createFontStyles(quoteL),
  subtitle: createFontStyles(subTitle),
  titleL: createFontStyles(titleL),
  titleM: createFontStyles(titleM),
  titleS: createFontStyles(titleS),
  titleXL: createFontStyles(titleXL),
  titleXS: createFontStyles(titleXS),
  titleXXS: createFontStyles(titleXXS)
} as const;

/**
 * `Props` type.
 */

type Props = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small' | 'span' | 'strong';
  bold?: boolean;
  children?: ReactNode;
  noMargin?: boolean;
  uppercase?: boolean;
  variant?: keyof typeof textStyles;
};

/**
 * Export `Text` styled component.
 */

export const Text = styled.span<Props>`
  ${switchProp('variant', textStyles, textStyles.titleXL)}
  ${ifProp('uppercase', 'text-transform: uppercase;')}
`;
