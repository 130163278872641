/**
 * Module dependencies,
 */

import { Breakpoint, breakpoints } from './breakpoints';
import { FlattenSimpleInterpolation, css } from 'styled-components';

/**
 * `CssArgs` type.
 */

type CssArgs = TemplateStringsArray | [];

/**
 * `Media` type.
 */

type Media = Record<
  'min' | 'max',
  Record<Breakpoint, (styles: TemplateStringsArray, ...interpolations: CssArgs) => FlattenSimpleInterpolation>
>;

/**
 * Render query.
 */

function renderQuery(orientation: string, value: number) {
  return (styles: TemplateStringsArray, ...interpolations: CssArgs) => css`
    /* stylelint-disable */
    @media (${orientation}: ${value}px) {
      ${css(styles, ...interpolations)}
    }
    /* stylelint-enable */
  `;
}

/**
 * Media util.
 */

// @ts-ignore
export const media = Object.entries(breakpoints).reduce<Media>(
  (queries, [key, value]: [string, number]) => ({
    max: {
      ...queries.max,
      [key]: renderQuery('max-width', value - 1)
    },
    min: {
      ...queries.min,
      [key]: renderQuery('min-width', value)
    }
  }),
  {
    max: {},
    min: {}
  } as Media
);
