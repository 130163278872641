/**
 * Export `cssVariables`.
 */

export const cssVariables = `
  :root {
    /**
     * Colors.
     */

    --color-black: #000000;
    --color-dark: #171717;
    --color-grey: #9a9a9a;
    --color-grey-100: #f2f2f2;
    --color-grey-200: #efefef;
    --color-grey-300: #d0d0d0;
    --color-grey-400: #bcbcbc;
    --color-grey-500: #727272;
    --color-grey-600: #707070;
    --color-grey-700: #4b4b4b;
    --color-grey-800: #404040;
    --color-white: #ffffff;
    --color-yellow: #fdb718;

    /**
     * Colors transparency.
     */

    --color-black-opacity-40: #00000040;
    --color-white-opacity-10: #ffffff10;

    /**
     * Font family.
     */

    --font-baskerville: baskerville-urw, serif;
    --font-futura-pt-bold: futura-pt-bold, sans-serif;
    --font-futura-pt: futura-pt, sans-serif;
    --font-handwriting: adobe-handwriting-ernie, serif;
    --font-glowbetter: GlowBetter-Script, cursive;

    /**
     * Grid.
     */

    --gutter: 24px;
    --gutter-mobile: 16px;

    /**
     * Container.
     */

    --container-max-width: 1824px;

    /**
     * Transitions.
     */

    --transition-default-animation: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-fast: 150ms var(--transition-default-animation);
    --transition-default: 350ms var(--transition-default-animation);
  }
`;
