/**
 * Module dependencies.
 */

import { useCallback } from 'react';

/**
 * Constants.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * `Result` type.
 */

type Result = {
  onPageView: (url: string) => void;
};

/**
 * `useGTM` hook.
 */

function useGTM(): Result {
  const onPageView = useCallback((url: string) => {
    if (isProduction && googleTagManagerId && window?.dataLayer) {
      window?.dataLayer?.push({
        event: 'pageview',
        page: url
      });
    }
  }, []);

  return {
    onPageView
  };
}

/**
 * Export `useGTM` hook.
 */

export default useGTM;
