/**
 * Export `i18nRoutes`.
 */

module.exports = {
  en: {
    home: '/',
    peixotoBio: '/biography/jose-luis-peixoto',
    saramagoBio: '/biography/jose-saramago',
    tour: '/tour/',
    tourDetails: '/tour/:slug',
    tripToPortugal: '/trip-to-portugal',
    writers: '/writers'
  },
  es: {
    home: '/',
    peixotoBio: '/biografia/jose-luis-peixoto',
    saramagoBio: '/biografia/jose-saramago',
    tour: '/tour/',
    tourDetails: '/tour/:slug',
    tripToPortugal: '/viaje-a-portugal',
    writers: '/escritores'
  },
  pt: {
    home: '/',
    peixotoBio: '/biografia/jose-luis-peixoto',
    saramagoBio: '/biografia/jose-saramago',
    tour: '/tour/',
    tourDetails: '/tour/:slug',
    tripToPortugal: '/viagem-a-portugal',
    writers: '/escritores'
  }
};
