/**
 * Module dependencies.
 */

import { ifNotProp } from 'styled-tools';
import { media } from 'src/styles/media';
import styled, { css } from 'styled-components';

/**
 * `Container` component.
 */

const Container = styled.div<{ fluid?: boolean }>`
  padding: 0 var(--gutter-mobile);
  width: 100%;

  ${media.min.lg`
    padding: 0 var(--gutter);
  `}

  ${ifNotProp(
    'fluid',
    css`
      margin-left: auto;
      margin-right: auto;
      max-width: var(--container-max-width);
    `
  )}
`;

/**
 * Export `Container` component.
 */

export default Container;
