/**
 * Module dependencies.
 */

import { NavbarTheme } from 'src/types/navbar';
import { switchProp } from 'styled-tools';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import iconArrowLeft from 'src/assets/svg/arrow-left.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * Export `NavigationProps` interface.
 */

export type NavigationProps = {
  className?: string;
  colorTheme: NavbarTheme;
  href?: string;
  onClick?: () => void;
};

/**
 * Set color theme.
 */

const setColorTheme = css`
  ${switchProp('colorTheme', {
    dark: css`
      color: var(--color-black);
    `,
    light: css`
      color: var(--color-white);
    `
  })}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs<NavigationProps>(({ href }) => {
  const element = (href && RouterLink) || 'button';

  return {
    as: element,
    type: element === 'button' ? 'button' : null
  };
})<{ colorTheme: NavbarTheme }>`
  ${setColorTheme}

  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  grid-gap: 1rem;
  left: 1.25rem;
  opacity: 1;
  outline: none;
  padding: 0;
  position: fixed;
  top: 1.25rem;
  transition: opacity 0.35s ease;
  z-index: 99;

  &:focus,
  &:hover {
    opacity: 0.5;
  }
`;

/**
 * `Label` styled component.
 */

const Label = styled.p`
  font-family: var(--font-futura-pt);
  font-size: 0.75rem;
  letter-spacing: 0.1875rem;
  line-height: 1.125rem;
  text-transform: uppercase;
`;

/**
 * `Navigation` component.
 */

const Navigation = (props: NavigationProps): ReactElement => {
  const { className, colorTheme, href, onClick } = props;
  const { locale } = useRouter();
  const { translate } = useTranslate();

  return (
    <Wrapper
      className={className}
      colorTheme={colorTheme}
      {...(href ? { href, locale } : { onClick })}
    >
      <Svg
        icon={iconArrowLeft}
        size={'2.125rem'}
      />

      <Label>{translate('common:actions.back')}</Label>
    </Wrapper>
  );
};

/**
 * Export `Navigation` component.
 */

export default Navigation;
