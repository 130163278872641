/**
 * Export `i18n` configuration.
 */

module.exports = {
  defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  loadLocaleFrom: (locale, namespace) => {
    return import(`./public/locales/${locale}/${namespace}.json`);
  },
  locales: process.env.NEXT_PUBLIC_LOCALES.split(','),
  pages: {
    '*': ['common'],
    '/': ['homepage'],
    '/biografia/jose-luis-peixoto': ['biography-peixoto'],
    '/biografia/jose-saramago': ['biography-saramago'],
    '/escritores': ['writers'],
    '/viagem-a-portugal': ['trip-to-portugal'],
    'rgx:^/tour': ['tour']
  }
};
