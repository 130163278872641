/**
 * Module dependencies.
 */

import { FC, ReactNode, forwardRef } from 'react';
import NextLink, { LinkProps as RouterLinkProps } from 'next/link';

/**
 * `LinkProps` type.
 */

interface LinkProps extends RouterLinkProps {
  children: ReactNode;
  className?: string;
}

/**
 * `RouterLink` component.
 */

const RouterLink: FC<LinkProps> = forwardRef((props: LinkProps, ref: any) => {
  const { children, className, ...rest } = props;

  return (
    <NextLink
      className={className}
      passHref
      ref={ref}
      {...rest}
    >
      {children}
    </NextLink>
  );
});

/**
 * `RouterLink` display name.
 */

RouterLink.displayName = 'RouterLink';

/**
 * Export `RouterLink` component.
 */

export default RouterLink;
