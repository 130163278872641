/**
 * Module dependencies.
 */

import { Menu as MenuType, useMenu } from 'src/core/content-config/menu';
import { clampCalc } from 'src/components/core/text';
import { ifProp } from 'styled-tools';
import { media } from 'src/styles/media';
import { termsUrl } from 'src/core/content-config/footer';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import Image from 'src/components/core/image';
import React, { ReactElement, useCallback } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import iconHome from 'src/assets/svg/home.svg';
import styled, { css } from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: visibility 0s;
  z-index: 101;

  ${media.max.md`
    overflow-y: auto;
  `}

  ${ifProp(
    'visible',
    css`
      transition-delay: 0s;
      visibility: visible;
    `,
    css`
      transition-delay: 0.85s;
      visibility: hidden;
    `
  )}
`;

/**
 * `InnerWrapper` styled component.
 */

const InnerWrapper = styled.div<{ visible: boolean }>`
  bottom: 0;
  left: 0;
  min-height: 100%;
  position: relative;
  right: 0;
  top: 0;
  transform-origin: center right;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;

  ${media.min.md`
    background-color: var(--color-dark);
    overflow-y: auto;
    position: absolute;
  `}

  ${ifProp(
    'visible',
    css`
      transform: translateX(0);
      transition-delay: 0s;
    `,
    css`
      transform: translateX(100%);
      transition-delay: 0.25s;
    `
  )}
`;

/**
 * `Menu` styled component.
 */

const Menu = styled.div`
  padding: 12.5rem 1rem 2.375rem;

  ${media.min.md`
    height: 100%;
    padding: 7.75rem 8.625rem 8.625rem;
  `}
`;

/**
 * `InnerMenu` styled component.
 */

const InnerMenu = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
`;

/**
 * `MenuList` styled component.
 */

const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  ${media.min.md`
    grid-gap: 3.25rem;
  `}

  ${media.max.md`
    margin-bottom: 9.625rem;
  `}
`;

/**
 * `MenuItem` styled component.
 */

const MenuItem = styled.li`
  font-family: var(--font-futura-pt);
  font-size: clamp(24px, ${clampCalc(24, 45)}, 45px);
  letter-spacing: clamp(1.2px, ${clampCalc(1.2, 6.75)}, 6.75px);
  line-height: clamp(30px, ${clampCalc(30, 58)}, 58px);
  opacity: 1;
  text-align: center;
  text-transform: uppercase;
  transition: opacity var(--transition-default);

  &:focus,
  &:hover {
    opacity: 0.5;
  }
`;

/**
 * `MenuLink` styled component.
 */

const MenuLink = styled(RouterLink)`
  color: var(--color-white);
`;

/**
 * `FooterLinkList` styled component.
 */

const FooterLinkList = styled.ul`
  align-items: center;
  display: flex;
  grid-area: links;
  grid-gap: 2rem;
  justify-content: center;

  ${media.min.md`
    padding-top: 4.625rem;
  `}

  ${media.max.md`
    flex-direction: column;
  `}
`;

/**
 * `FooterLinkItem` styled component.
 */

const FooterLinkItem = styled.li`
  position: relative;

  ${media.min.md`
    &::after {
      background-color: var(--color-grey-600);
      content: '';
      height: 1rem;
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  `}
`;

/**
 * `FooterLink` styled component.
 */

const FooterLink = styled.a`
  align-items: center;
  color: inherit;
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  font-family: var(--font-futura-pt);
  font-size: 1.15rem;
  letter-spacing: 0;
  line-height: 1.15rem;
  margin: 0;
  opacity: 1;
  text-decoration: none;
  transition: var(--transition-default);
  transition-property: color, opacity;

  ${media.min.md`
    color: var(--color-grey-600);
    font-size: 0.9rem;
    line-height: 0.9rem;
  `}

  &:focus,
  &:hover {
    ${media.min.md`
      color: var(--color-white);
    `}

    ${media.max.md`
      opacity: 0.5;
    `}
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  pointer-events: none;
  z-index: -1;

  ${media.max.md`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `}
`;

/**
 * `StyledSvg` styled component.
 */

const StyledSvg = styled(Svg)`
  color: var(--color-grey-400);
`;

/**
 * `LinkHome` styled component.
 */

const LinkHome = styled.a.attrs(({ href }) => ({
  as: (href && RouterLink) || 'a'
}))`
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  grid-gap: 1rem;
  left: 1.125rem;
  opacity: 1;
  outline: none;
  padding: 0;
  position: fixed;
  top: 2.0625rem;
  transition: opacity 0.35s ease;
  z-index: 101;

  ${media.min.md`
    left: 1.25rem;
    top: 2.4375rem;
  `}

  &:focus,
  &:hover {
    opacity: 0.5;
  }
`;

/**
 * `Label` styled component.
 */

const Label = styled.p`
  font-family: var(--font-futura-pt-bold);
  font-size: 0.75rem;
  letter-spacing: 0.1875rem;
  line-height: 1.125rem;
  text-transform: uppercase;
`;

/**
 * `Sidebar` component.
 */

const Sidebar = ({ isOpen, onClick }: Props): ReactElement => {
  const { translate } = useTranslate();
  const menuItems = useMenu();
  const isMobile = useBreakpoint('md', 'max');
  const router = useRouter();
  const routeResolver = useLocalizedRoute();
  const handleClickLogo = useCallback(() => {
    if (router.pathname !== routeResolver('home')) {
      router.push(routeResolver('home'));
    }

    if (router.pathname === routeResolver('home')) {
      onClick();

      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0
      });
    }
  }, [onClick, routeResolver, router]);

  // @ts-expect-error locale is defined
  const termsLinkUrl = termsUrl[router?.locale as string];

  return (
    <Wrapper visible={isOpen}>
      <InnerWrapper visible={isOpen}>
        <LinkHome onClick={handleClickLogo}>
          <StyledSvg icon={iconHome} size={'2.125rem'} />

          <Label>{translate('common:actions.home')}</Label>
        </LinkHome>

        {isMobile && (
          <ImageWrapper>
            <Image
              alt={translate('common:menu.title')}
              layout={'fill'}
              objectFit={'cover'}
              src={'/images/saramago-vertical.jpg'}
            />
          </ImageWrapper>
        )}

        <Menu>
          <InnerMenu>
            {!isMobile && (
              <ImageWrapper>
                <Image
                  alt={translate('common:menu.title')}
                  layout={'fill'}
                  objectFit={'cover'}
                  src={'/images/saramago.jpg'}
                />
              </ImageWrapper>
            )}

            <MenuList>
              {menuItems.map(({ href, label }: MenuType, index: number) => (
                <MenuItem key={index}>
                  <MenuLink href={href} locale={router?.locale}>
                    {translate(label)}
                  </MenuLink>
                </MenuItem>
              ))}
            </MenuList>

            {isMobile && (
              <FooterLinkList>
                <FooterLinkItem>
                  <FooterLink href={termsLinkUrl} rel={'noopener'} target={'_blank'}>
                    {translate('common:footer.termsPolicy')}
                  </FooterLink>
                </FooterLinkItem>
              </FooterLinkList>
            )}
          </InnerMenu>

          {!isMobile && (
            <FooterLinkList>
              <FooterLinkItem>
                <FooterLink href={termsLinkUrl} rel={'noopener'} target={'_blank'}>
                  {translate('common:footer.termsPolicy')}
                </FooterLink>
              </FooterLinkItem>
            </FooterLinkList>
          )}
        </Menu>
      </InnerWrapper>
    </Wrapper>
  );
};

/**
 * Export `Sidebar` component.
 */

export default Sidebar;
