/**
 * Module dependencies.
 */

import { NavbarTheme } from 'src/types/navbar';
import { NavbarThemeContext } from './context';
import React, { ReactElement, ReactNode, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `NavbarThemeProvider` provider.
 */

const NavbarThemeProvider = ({ children }: Props): ReactElement => {
  const [theme, setTheme] = useState<NavbarTheme>('light');

  return (
    <NavbarThemeContext.Provider
      value={{
        setTheme,
        theme
      }}
    >
      {children}
    </NavbarThemeContext.Provider>
  );
};

/**
 * Export `NavbarThemeProvider` provider.
 */

export default NavbarThemeProvider;
