/**
 * Module dependencies.
 */

import facebookLogo from 'src/assets/svg/facebook.svg';
import instagramLogo from 'src/assets/svg/instagram.svg';
import twitterLogo from 'src/assets/svg/twitter.svg';

/**
 * Export `termsUrl` type.
 */

export const termsUrl = {
  en: '/files/terms-of-use-and-personal-data-processing.pdf',
  pt: '/files/termos-de-utilizacao-e-tratamento-de-dados-pessoais.pdf'
};

/**
 * Export `Social` type.
 */

export type Social = {
  href: string;
  icon: string;
  title: string;
};

/**
 * Export `visitPortugalSocialNetworks`.
 */

export const visitPortugalSocialNetworks: Social[] = [
  {
    href: 'https://pt-pt.facebook.com/Visitportugal',
    icon: facebookLogo,
    title: 'Facebook'
  },
  {
    href: 'https://twitter.com/visitportugal',
    icon: twitterLogo,
    title: 'Twitter'
  },
  {
    href: 'https://www.instagram.com/visitportugal',
    icon: instagramLogo,
    title: 'Instagram'
  }
];

/**
 * Export `fundacaoSocialNetworks`.
 */

export const fundacaoSocialNetworks: Social[] = [
  {
    href: 'https://www.facebook.com/fjsaramago',
    icon: facebookLogo,
    title: 'Facebook'
  },
  {
    href: 'https://twitter.com/FJSaramago',
    icon: twitterLogo,
    title: 'Twitter'
  },
  {
    href: 'https://www.instagram.com/fjsaramago',
    icon: instagramLogo,
    title: 'Instagram'
  }
];
