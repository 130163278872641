/**
 * Module dependencies.
 */

import { ifProp } from 'styled-tools';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  active: boolean;
  className?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span<Pick<Props, 'active'>>`
  display: inline-block;
  left: 50%;
  opacity: ${ifProp('active', 1, 0)};
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  transition: transform var(--transition-default);

  ${ifProp(
    'active',
    css`
      transform: scale(1);
    `
  )}
`;

/**
 * `Loader` styled component.
 */

const Loader = styled.span`
  animation: spin 1.25s linear infinite;
  border-left-color: transparent;
  border-radius: 1.5rem;
  border-style: solid;
  border-width: 0.125rem;
  color: var(--color-dark);
  display: block;
  height: 1.5rem;
  width: 1.5rem;
`;

/**
 * `Loading` component.
 */

const Loading = (props: Props): ReactElement => {
  const { active, className } = props;

  return (
    <Wrapper
      active={active}
      className={className}
    >
      <Loader />
    </Wrapper>
  );
};

/**
 * Export `Loading` component.
 */

export default Loading;
