/**
 * Module dependencies.
 */

import { Context, Dispatch, SetStateAction, createContext, useContext } from 'react';

import { NavbarTheme } from 'src/types/navbar';

/**
 * `NavbarType` type.
 */

type NavbarType = {
  setTheme: Dispatch<SetStateAction<NavbarTheme>>;
  theme: NavbarTheme;
};

/**
 * Export `NavbarThemeContext` context.
 */

export const NavbarThemeContext: Context<NavbarType> = createContext({} as NavbarType);

/**
 * Export `useNavbarTheme` hook.
 */

export const useNavbarTheme = () => useContext(NavbarThemeContext);
