/**
 * Module dependencies.
 */

import { clampCalc } from 'src/components/core/text';
import { ifProp, switchProp } from 'styled-tools';
import React, { FC, ReactElement, ReactNode, forwardRef, useRef } from 'react';
import styled, { css } from 'styled-components';

/**
 * `ButtonProps` type.
 */

type ButtonProps = {
  'aria-expanded': boolean;
  'aria-haspopup': boolean;
  role: string;
  tabIndex: number;
};

/**
 * Export `DropdownProps` interface.
 */

export interface DropdownProps {
  children: ReactNode;
  className?: string;
  colorTheme: string;
  isOpen: boolean;
  ref?: any;
  renderButton: (props: ButtonProps) => ReactElement;
  width?: string;
}

/**
 * ClampValue.
 */

const clampValue = clampCalc(60, 68);

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

/**
 * `Menu` styled component.
 */

const Menu = styled.div<{
  colorTheme: string;
  isOpen: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: -1px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 100%;
  transition: 0.35s ease;
  transition-property: opacity, transform, visibility;
  visibility: hidden;
  width: clamp(60px, ${clampValue}, 68px);

  ${ifProp(
    'isOpen',
    css`
      opacity: 1;
      transform: translate(0, 0);
      visibility: visible;
    `
  )}

  ${switchProp('colorTheme', {
    dark: css`
      background-color: var(--color-black);
      color: var(--color-white);
    `,
    light: css`
      background-color: var(--color-white);
      color: var(--color-black);
    `
  })}
`;

/**
 * `Dropdown` component.
 */

const Dropdown: FC<DropdownProps> = forwardRef<any, DropdownProps>((props: DropdownProps): ReactElement => {
  const { children, colorTheme, isOpen, renderButton } = props;
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper>
      {renderButton({
        'aria-expanded': isOpen,
        'aria-haspopup': true,
        role: 'button',
        tabIndex: 0
      })}

      <Menu colorTheme={colorTheme} isOpen={isOpen} ref={menuRef} role={'menu'} tabIndex={-1}>
        {children}
      </Menu>
    </Wrapper>
  );
});

/**
 * `Dropdown` display name.
 */

Dropdown.displayName = 'Dropdown';

/**
 * Export `Dropdown` component.
 */

export default Dropdown;
