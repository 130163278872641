
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /**
 * Module dependencies.
 */
import { GlobalStyle } from 'src/styles/global';
import { ThemeProvider } from 'styled-components';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import GridDebug from 'src/components/core/debug/grid';
import Head from 'next/head';
import Navbar from 'src/components/navbar';
import NavbarThemeProvider from 'src/context/navbar-theme/provider';
import NextApp, { AppContext, AppProps } from 'next/app';
import PageTransition from 'src/components/page-transition';
import Script from 'next/script';
import nookies from 'nookies';
import packageJson from 'package.json';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useGTM from 'src/hooks/use-gtm';
/**
 * Constants.
 */
const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';
/**
 * `App` page.
 */
const App = ({ Component, pageProps }: AppProps) => {
    const router = useRouter();
    const { onPageView } = useGTM();
    const isMobile = useBreakpoint('lg', 'max');
    useEffect(() => {
        router.events.on('routeChangeComplete', onPageView);
        return () => {
            router.events.off('routeChangeComplete', onPageView);
        };
    }, [onPageView, router.events]);
    return (<>
      <Head>
        <meta charSet={'UTF-8'}/>

        <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'}/>

        <meta content={'width=device-width, initial-scale=1'} name={'viewport'}/>

        <meta content={'true'} name={'HandheldFriendly'}/>

        <meta content={packageJson.version} name={'version'}/>

        <link href={'/favicon/apple-icon-57x57.png'} rel={'apple-touch-icon'} sizes={'57x57'}/>

        <link href={'/favicon/apple-icon-60x60.png'} rel={'apple-touch-icon'} sizes={'60x60'}/>

        <link href={'/favicon/apple-icon-72x72.png'} rel={'apple-touch-icon'} sizes={'72x72'}/>

        <link href={'/favicon/apple-icon-76x76.png'} rel={'apple-touch-icon'} sizes={'76x76'}/>

        <link href={'/favicon/apple-icon-114x114.png'} rel={'apple-touch-icon'} sizes={'114x114'}/>

        <link href={'/favicon/apple-icon-120x120.png'} rel={'apple-touch-icon'} sizes={'120x120'}/>

        <link href={'/favicon/apple-icon-144x144.png'} rel={'apple-touch-icon'} sizes={'144x144'}/>

        <link href={'/favicon/apple-icon-152x152.png'} rel={'apple-touch-icon'} sizes={'152x152'}/>

        <link href={'/favicon/apple-icon-180x180.png'} rel={'apple-touch-icon'} sizes={'180x180'}/>

        <link href={'/favicon/android-icon-192x192.png'} rel={'icon'} sizes={'192x192'} type={'image/png'}/>

        <link href={'/favicon/favicon-32x32.png'} rel={'icon'} sizes={'32x32'} type={'image/png'}/>

        <link href={'/favicon/favicon-96x96.png'} rel={'icon'} sizes={'96x96'} type={'image/png'}/>

        <link href={'/favicon/favicon-16x16.png'} rel={'icon'} sizes={'16x16'} type={'image/png'}/>

        <link href={'/favicon.ico'} rel={'shortcut icon'} type={'image/x-icon'}/>

        <link href={'/favicon.ico'} rel={'icon'} type={'image/x-icon'}/>

        <link href={'/manifest.json'} rel={'manifest'}/>

        <meta content={'#ffffff'} name={'msapplication-TileColor'}/>

        <meta content={'/favicon/ms-icon-144x144.png'} name={'msapplication-TileImage'}/>

        <meta content={'#ffffff'} name={'theme-color'}/>

        <script 
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `history.scrollRestoration = "manual"`
        }}/>
      </Head>

      {isProduction && googleTagManagerId && (<Script 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
                // eslint-disable-next-line id-match
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${googleTagManagerId}');
              `
            }} id={'gtag-base'} strategy={'beforeInteractive'}/>)}

      <ThemeProvider theme={{}}>
        {!isProduction && <GridDebug columns={12} gap={isMobile ? 16 : 24}/>}

        <NavbarThemeProvider>
          <GlobalStyle />

          <Navbar />

          <PageTransition>
            <Component {...pageProps}/>
          </PageTransition>
        </NavbarThemeProvider>
      </ThemeProvider>
    </>);
};
/**
 * Get initial props.
 */
App.getInitialProps = async (appContext: AppContext) => {
    const { ctx, router } = appContext;
    // // Set `locale` cookie.
    nookies.set(ctx, 'NEXT_LOCALE', router?.locale as string, {
        maxAge: 3650 * 24 * 60 * 60,
        path: '/'
    });
    return await NextApp.getInitialProps(appContext);
};
const __Next_Translate__Page__18f3eeff7e8__ = App;

    export default __appWithI18n(__Next_Translate__Page__18f3eeff7e8__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  