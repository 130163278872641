/**
 * Module dependencies.
 */

import NextImage, { ImageProps as NextImageProps } from 'next/legacy/image';

/**
 * Export `ImageProps` type.
 */

export type ImageProps = NextImageProps & {
  className?: string;
};

/**
 * `Image` component.
 */

const Image = (props: ImageProps) => <NextImage {...props} />;

/**
 * Export `Image` component.
 */

export default Image;
