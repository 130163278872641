/**
 * Module dependencies.
 */

import { Breakpoint, breakpoints } from 'src/styles/breakpoints';
import useMediaQuery from './use-media-query';

/**
 * Export `Type` type.
 */

export type Type = 'min' | 'max';

/**
 * Export `Options` interface.
 */

export interface Options {
  breakpoint: Breakpoint;
  type: Type;
}

/**
 * Get breakpoint value.
 */

function getBreakpointValue(breakpoint: Breakpoint, type: Type): number {
  const value = breakpoints[breakpoint];

  return type === 'max' ? value - 1 : value;
}

/**
 * `useBreakpoint` hook.
 */

function useBreakpoint(breakpoint: Breakpoint, type: Type = 'min'): boolean {
  const breakpointValue = getBreakpointValue(breakpoint, type);
  const query = `(${type}-width: ${breakpointValue}px)`;

  return useMediaQuery(query);
}

/**
 * Export `useBreakpoint` hook.
 */

export default useBreakpoint;
