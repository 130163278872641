/**
 * Module dependencies.
 */

import { NavbarTheme } from 'src/types/navbar';
import { baseFontSize, clampCalc } from 'src/components/core/text';
import { ifProp, switchProp } from 'styled-tools';
import { media } from 'src/styles/media';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useNavbarTheme } from 'src/context/navbar-theme/context';
import { useRouter } from 'next/router';
import Dropdown from 'src/components/core/dropdown';
import HamburgerMenu from './hamburger-menu';
import Navigation from 'src/components/core/navigation';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Sidebar from './sidebar';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useScroll from 'src/hooks/use-scroll';

/**
 * ClampValue.
 */

const clampValue = clampCalc(60, 68);

/**
 * Set color theme.
 */

const setColorTheme = css`
  ${switchProp('colorTheme', {
    dark: css`
      color: var(--color-black);
    `,
    light: css`
      color: var(--color-white);
    `
  })}
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  align-items: center;
  display: flex;
  position: fixed;
  right: 1.125rem;
  top: 1.125rem;
  z-index: 104;

  ${media.min.md`
    right: 1.25rem;
    top: 1.25rem;
  `}
`;

/**
 * `Box` styled component.
 */

const Box = styled.div<{ colorTheme: NavbarTheme }>`
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  height: clamp(60px, ${clampValue}, 68px);
  justify-content: center;
  transition: 0.35s ease;
  transition-delay: 0s;
  transition-property: color, opacity, transform, visibility;
  width: clamp(60px, ${clampValue}, 68px);

  ${setColorTheme}
`;

/**
 * `HamburgerMenuWrapper` styled component.
 */

const HamburgerMenuWrapper = styled(Box)<{ isMenuOpen: boolean }>`
  ${ifProp(
    'isMenuOpen',
    css`
      color: var(--color-white);
    `
  )}
`;

/**
 * `LanguageWrapper` styled component.
 */

const LanguageWrapper = styled(Box)<{
  isLangOpen: boolean;
  isMenuOpen: boolean;
}>`
  opacity: 1;

  ${setColorTheme}

  ${ifProp(
    'isLangOpen',
    css`
      transition: opacity var(--transition-default) 0.75s;

      ${switchProp('colorTheme', {
        dark: css`
          background-color: var(--color-black);
          border-color: var(--color-black);
          color: var(--color-white);
        `,
        light: css`
          background-color: var(--color-white);
          border-color: var(--color-white);
          color: var(--color-black);
        `
      })}
    `
  )}

  ${ifProp(
    'isMenuOpen',
    css`
      opacity: 0;
      pointer-events: none;
      transition-delay: 0s;
    `
  )}
`;

/**
 * `ButtonLang` styled component.
 */

const ButtonLang = styled.button`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: var(--font-futura-pt-bold);
  font-size: clamp(${12 / baseFontSize}rem, ${clampCalc(12, 14)}, ${14 / baseFontSize}rem);
  letter-spacing: clamp(${3 / baseFontSize}rem, ${clampCalc(3, 3.5)}, ${3.5 / baseFontSize}rem);
  line-height: clamp(${60 / baseFontSize}rem, ${clampCalc(60, 66)}, ${66 / baseFontSize}rem);
  outline: none;
  text-transform: uppercase;
  transition: color var(--default-transition);
`;

/**
 * `LangLink` styled component.
 */

const LangLink = styled(RouterLink)`
  border-bottom: 1px solid;
  cursor: pointer;
  text-transform: uppercase;

  &:first-of-type {
    border-top: 1px solid;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  span {
    font-family: var(--font-futura-pt-bold);
    font-size: clamp(${12 / baseFontSize}rem, ${clampCalc(12, 14)}, ${14 / baseFontSize}rem);
    letter-spacing: clamp(${3 / baseFontSize}rem, ${clampCalc(3, 3.5)}, ${3.5 / baseFontSize}rem);
    line-height: clamp(${60 / baseFontSize}rem, ${clampCalc(60, 66)}, ${66 / baseFontSize}rem);
  }
`;

/**
 * `LanguageUnderlay` styled component.
 */

const LanguageUnderlay = styled.div<{ isVisible: boolean }>`
  bottom: 0;
  left: 0;
  pointer-events: ${ifProp('isVisible', 'auto', 'none')};
  position: fixed;
  right: 0;
  top: 0;
  z-index: 103;
`;

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;
`;

/**
 * `NavUnderlay` styled component.
 */

const NavUnderlay = styled.div<{
  isMenuOpen: boolean;
  isVisible: boolean;
}>`
  background-color: var(--color-dark);
  height: 6rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${ifProp('isVisible', 'translateY(0)', 'translateY(-100%)')};
  transition: transform var(--transition-default);
  z-index: 101;

  ${ifProp(
    'isMenuOpen',
    css`
      transform: translateY(-100%);
      transition-delay: 0.3s;
    `
  )}
`;

/**
 * `Navbar` component.
 */

const Navbar = (): ReactElement => {
  const { setTheme, theme } = useNavbarTheme();
  const { locale, locales } = useRouter();
  const isMobile = useBreakpoint('md', 'max');
  const { yPos } = useScroll();
  const router = useRouter();
  const routeResolver = useLocalizedRoute();
  const tourDetailsRoute = routeResolver('tourDetails').replace(':slug', '[slug]');

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isLangOpen, setLangOpen] = useState<boolean>(false);
  const dropdownRef = useRef();
  const activeLang = useMemo(() => {
    return map(locales, language => {
      if (language === locale) {
        return language;
      }
    });
  }, [locale, locales]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMenuOpen) {
        setMenuOpen(false);
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [isMenuOpen, router.events]);

  const handleClickOutside = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setLangOpen(false);
  }, []);

  const isScrolled = useMemo(() => {
    if (!isMobile) {
      setTheme(theme);
    }

    return yPos > 100;
  }, [isMobile, setTheme, theme, yPos]);

  const handleSidebar = useCallback(() => {
    if (isMenuOpen) {
      setTimeout(() => {
        setMenuOpen(false);
      }, 100);
    }
  }, [isMenuOpen]);

  useBodyScroll({ off: isMenuOpen });

  return (
    <>
      {isMobile && <NavUnderlay isMenuOpen={isMenuOpen} isVisible={isScrolled} />}

      {!isMobile && router.pathname === tourDetailsRoute && (
        <Navigation colorTheme={theme} href={routeResolver('tour')} />
      )}

      <Actions>
        {locales && locales.length > 1 && (
          <LanguageWrapper
            colorTheme={isMobile && isScrolled ? 'light' : theme}
            isLangOpen={isLangOpen}
            isMenuOpen={isMenuOpen}
            onClick={() => setLangOpen(!isLangOpen)}
          >
            <Dropdown
              colorTheme={theme}
              isOpen={isLangOpen}
              ref={dropdownRef}
              renderButton={buttonProps => (
                <ButtonLang {...buttonProps} onClick={() => setLangOpen(!isLangOpen)}>
                  {activeLang}
                </ButtonLang>
              )}
              width={'36px'}
            >
              {map(
                locales,
                language =>
                  !(language === locale) && (
                    <LangLink href={routeResolver('home')} key={language} locale={language}>
                      <span onClick={() => setLangOpen(!isLangOpen)}>{language}</span>
                    </LangLink>
                  )
              )}
            </Dropdown>
          </LanguageWrapper>
        )}

        <HamburgerMenuWrapper
          colorTheme={isMobile && isScrolled ? 'light' : theme}
          isMenuOpen={isMenuOpen}
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <HamburgerMenu
            colorTheme={isMobile && isScrolled ? 'light' : theme}
            isOpen={isMenuOpen}
            onClick={() => setMenuOpen(!isMenuOpen)}
          />
        </HamburgerMenuWrapper>
      </Actions>

      <LanguageUnderlay isVisible={isLangOpen} onClick={handleClickOutside} />

      <Nav>
        <Sidebar isOpen={isMenuOpen} onClick={handleSidebar} />
      </Nav>
    </>
  );
};

/**
 * Export `Navbar` component.
 */

export default Navbar;
