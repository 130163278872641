/**
 * Module dependencies.
 */

import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMemo } from 'react';

/**
 * Export `Menu` type.
 */

export type Menu = {
  href: string;
  label: string;
};

/**
 * Export `useMenu`.
 */

export function useMenu(): Menu[] {
  const routeResolver = useLocalizedRoute();

  const items = useMemo(() => {
    return [
      {
        href: routeResolver('tour'),
        label: 'common:menu.labels.journeyToPortugal'
      },
      {
        href: routeResolver('tripToPortugal'),
        label: 'common:menu.labels.about'
      },
      {
        href: routeResolver('saramagoBio'),
        label: 'common:menu.labels.saramago'
      },
      {
        href: routeResolver('peixotoBio'),
        label: 'common:menu.labels.peixoto'
      }
    ];
  }, [routeResolver]);

  return items;
}
