/**
 * Module dependencies.
 */

import { NavbarTheme } from 'src/types/navbar';
import { ifProp, switchProp } from 'styled-tools';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

/**
 * Constants.
 */

const duration = '0.3s';
const bezier = 'cubic-bezier(0.23, 1, 0.32, 1)';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  colorTheme: NavbarTheme;
  isOpen: boolean;
  onClick: () => void;
};

/**
 * Set active color theme.
 */

const setActiveColorTheme = css`
  ${switchProp('colorTheme', {
    dark: css`
      color: var(--color-black);
    `,
    light: css`
      color: var(--color-white);
    `
  })}
`;

/**
 * `Button` styled component.
 */

const Button = styled.button<{
  active: boolean;
  colorTheme: NavbarTheme;
}>`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  outline: none;

  ${setActiveColorTheme}

  &:hover,
  &:focus {
    opacity: 0.6;
  }

  ${ifProp(
    'active',
    css`
      color: var(--color-white);
    `
  )}
`;

/**
 * `Lines` styled component.
 */

const Lines = styled.span<{ active: boolean }>`
  position: relative;
  transition: background-color 0.35s ease;

  &,
  &::before,
  &::after {
    background-color: currentcolor;
    height: 1.91px;
    width: 32px;
  }

  &::before,
  &::after {
    content: '';
    left: 0;
    position: absolute;
  }

  &::before {
    bottom: 10px;
    transition:
      bottom ${duration} ${duration} ${bezier},
      transform ${duration} ${bezier};
  }

  &::after {
    top: 10px;
    transition:
      top ${duration} ${duration} ${bezier},
      transform ${duration} ${bezier};
  }

  ${ifProp(
    'active',
    css`
      background-color: transparent !important;

      ${setActiveColorTheme}

      &::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition:
          bottom ${duration} ${bezier},
          transform ${duration} ${duration} ${bezier};
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
        transition:
          top ${duration} ${bezier},
          transform ${duration} ${duration} ${bezier};
      }
    `
  )}
`;

/**
 * `HamburgerMenu` component.
 */

const HamburgerMenu = (props: Props): ReactElement => {
  const { className, colorTheme, isOpen, onClick } = props;

  return (
    <Button
      active={isOpen}
      className={className}
      colorTheme={colorTheme}
      onClick={onClick}
    >
      <Lines active={isOpen} />
    </Button>
  );
};

/**
 * Export `HamburgerMenu` component.
 */

export default HamburgerMenu;
