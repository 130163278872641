/**
 * Module dependencies.
 */

import { prop } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import React, { useState } from 'react';
import styled from 'styled-components';
import times from 'lodash/times';

/**
 * `Props` type.
 */

type Props = {
  columns: number;
  gap: number;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  * {
    height: 100%;
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div<Props>`
  display: grid;
  grid-gap: ${prop('gap', 0)}px;
  grid-template-columns: repeat(${prop('columns')}, 1fr);
`;

/**
 * `Column` styled component.
 */

const Column = styled.div`
  background-color: #abc2e6;
  opacity: 0.5;
`;

/**
 * `Button` styled component.
 */

const Button = styled.button`
  bottom: 3.5rem;
  font-size: 10px;
  left: 0.5rem;
  position: fixed;
  z-index: 999;
`;

/**
 * `GridDebug` component.
 */

const GridDebug = (props: Props) => {
  const { columns } = props;
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <Button onClick={() => setVisible(!visible)}>{'Grid'}</Button>

      {visible && (
        <Wrapper>
          <Container>
            <Grid {...props}>
              {times(columns, index => (
                <Column key={index} />
              ))}
            </Grid>
          </Container>
        </Wrapper>
      )}
    </>
  );
};

/**
 * Export `GridDebug` component.
 */

export default GridDebug;
